// src/services/api.ts
import axios from 'axios';
import tokenService from './tokenService';

const API_BASE_URL = 'https://bees-api.fly.dev';

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Request interceptor to include the JWT token in headers
api.interceptors.request.use(
  (config) => {
    const token = tokenService.getToken();
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token refreshing
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = tokenService.getRefreshToken();

      if (refreshToken) {
        try {
          const response = await axios.post(`${API_BASE_URL}/auth/refresh`, {
            refresh_token: refreshToken,
          });

          const { access_token, refresh_token } = response.data;

          tokenService.setToken(access_token);
          tokenService.setRefreshToken(refresh_token);

          // Update the Authorization header in the original request
          originalRequest.headers.Authorization = `Bearer ${access_token}`;

          return api(originalRequest);
        } catch (err) {
          // If refresh token is invalid, logout the user
          tokenService.setToken(null);
          tokenService.setRefreshToken(null);
          localStorage.removeItem('user');
          window.location.href = '/login';
        }
      } else {
        // No refresh token, redirect to login
        tokenService.setToken(null);
        tokenService.setRefreshToken(null);
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);

export default api;
