// src/components/NavigationBar.tsx
import React, { useState, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png';
import { AuthContext } from '../context/AuthContext';

const NavigationBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Importante para obter a rota atual
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const { user, logout } = useContext(AuthContext);

  const menuItems = user
    ? [
        { text: 'ENXAMES', path: '/enxames' },
        { text: 'ISCAS', path: '/baits' },
        { text: 'DASHBOARD', path: '/dashboard' },
        { text: 'MAPA', path: '/map' },
        {
          text: 'SAIR',
          action: () => {
            logout();
            navigate('/');
          },
        },
      ]
    : [
        { text: 'INÍCIO', path: '/' },
        { text: 'PRIVACIDADE', path: '/privacy' },
        { text: 'DELETAR CONTA', path: '/delete-account' },
        { text: 'LOGIN', path: '/login' },
      ];

  const handleNavigate = (item: any) => {
    if (item.action) {
      item.action();
    } else if (item.path.startsWith('#')) {
      const section = document.querySelector(item.path);
      section?.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate(item.path);
    }
    setDrawerOpen(false);
  };

  // Função para verificar se o item está selecionado
  const isSelected = (item: any) => {
    if (item.path && location.pathname === item.path) {
      return true;
    }
    return false;
  };

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: theme.palette.primary.main }}>
        <Toolbar>
          {/* Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <img src={logo} alt="Pollen Logo" style={{ width: '80px' }} />
          </Box>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
            {menuItems.map((item) => (
              <Button
                key={item.text}
                onClick={() => handleNavigate(item)}
                sx={{
                  color: isSelected(item)
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                  borderBottom: isSelected(item)
                    ? `2px solid ${theme.palette.secondary.main}`
                    : 'none',
                  borderRadius: 0,
                  '&:hover': {
                    color: theme.palette.secondary.main,
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>

          {/* Mobile Menu Icon */}
          <IconButton
            sx={{ display: { xs: 'flex', md: 'none' }, color: '#3E2723' }}
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: { backgroundColor: theme.palette.background.default },
        }}
      >
        <Box sx={{ width: 250, padding: 2 }}>
          <List>
            {menuItems.map((item) => (
              <ListItem
                component="li"
                key={item.text}
                onClick={() => handleNavigate(item)}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: isSelected(item)
                    ? theme.palette.action.selected
                    : 'transparent',
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
              >
                <ListItemText
                  primary={item.text}
                  sx={{ color: theme.palette.text.primary }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default NavigationBar;
