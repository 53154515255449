import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import DeleteAccount from './components/DeleteAccount';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import BaitDetails from './components/BaitDetails';
import BaitList from './components/BaitList';
import EnxameDetails from './components/EnxameDetails';
import EnxameList from './components/EnxameList';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import MapPage from './components/MapPage';


const App: React.FC = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
         <Route
          path="/map"
          element={
            <ProtectedRoute>
              <MapPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/enxames"
          element={
            <ProtectedRoute>
              <EnxameList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/enxames/:id"
          element={
            <ProtectedRoute>
              <EnxameDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/baits"
          element={
            <ProtectedRoute>
              <BaitList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/baits/:id"
          element={
            <ProtectedRoute>
              <BaitDetails />
            </ProtectedRoute>
          }
        />
        {/* Add other routes as necessary */}
      </Routes>
    </AuthProvider>
  );
};

export default App;
